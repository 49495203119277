<template>
  <div class="row">
    <div class="col-7">
      <v-card class="mx-auto">
        <v-toolbar color="primary elevation-0" dark>
          <v-icon left>mdi-format-page-break</v-icon>
          <v-toolbar-title>Seite: {{ this.data.title }}</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            v-if="pageVersion === 'dev'"
            color="yellow"
            class="black--text mx-2"
            @click="releaseDevVersion()"
          >
            Veröffentlichen
          </v-btn>

          <v-btn
            v-else-if="pageVersion === 'live'"
            color="yellow"
            class="black--text mx-2"
            @click="draftLiveVersion()"
          >
            In Arbeitsversion kopieren
          </v-btn>

          <v-select
            v-model="pageVersion"
            item-text="name"
            item-value="value"
            :items="[
              {
                name: 'Arbeitsversion',
                value: 'dev',
              },
              {
                name: 'Liveversion',
                value: 'live',
              },
            ]"
            label="Version"
            hide-details
            light
            dense
            solo
            @change="getData"
          ></v-select>

          <v-btn
            :href="whatUrl()"
            target="_blank"
            x-small
            color="blue"
            class="mx-1 white--text"
            fab
            ><v-icon dark>mdi-eye</v-icon></v-btn
          >
          <!-- <v-btn
            v-if="pageVersion === 'live'"
            :href="
              this.$frontendUrl +
                '?uuid=' +
                this.data.uuidPretty +
                '&type=page&branch=live'
            "
            target="_blank"
            x-small
            color="blue"
            class="mx-1 white--text"
            fab
            ><v-icon dark>mdi-eye</v-icon></v-btn
          > -->
        </v-toolbar>

        <v-card-text>
          <div v-if="pageVersion === 'dev'">
            <draggable
              class="dragArea list-group"
              :list="slicesDev"
              :group="{ name: 'elements', pull: 'clone', put: true }"
              ghost-class="ghost"
              handle=".draghandle"
              @add="addElement"
              @change="changeElement"
              d-flex
              flex-column
            >
              <div v-for="(element, index) in slicesDev" :key="index">
                <component
                  v-if="element.data"
                  v-bind:is="element.componentName"
                  :someSliceInEdit="someSliceInEdit"
                  :prio="index"
                  :sliceid="element.data.id"
                  :element="element"
                  :companies_id="data.companies_id"
                  :campaigns_id="data.campaigns_id"
                  @sliceAction="sliceAction"
                  @sliceOnEdit="aSliceIsInEdit"
                ></component>
                <component
                  v-else
                  v-bind:is="placeholderComponent"
                  :element="element"
                ></component
                ><!-- Platzhalter Component bis der neue Slice erstellt wurde -->
              </div>
            </draggable>
          </div>

          <div v-else-if="pageVersion === 'live'">
            <draggable
              class="dragArea list-group"
              :list="slicesLive"
              :group="{ name: 'elements', pull: 'clone', put: true }"
              ghost-class="ghost"
              handle=".draghandle"
              @add="addElement"
              @change="changeElement"
              d-flex
              flex-column
            >
              <div v-for="(element, index) in slicesLive" :key="index">
                <component
                  v-if="element.data"
                  v-bind:is="element.componentName"
                  :someSliceInEdit="someSliceInEdit"
                  :prio="index"
                  :sliceid="element.data.id"
                  :element="element"
                  :companies_id="data.companies_id"
                  :campaigns_id="data.campaigns_id"
                  :key="componentKey"
                  @sliceAction="sliceAction"
                  @sliceOnEdit="aSliceIsInEdit"
                ></component>
                <component
                  v-else
                  v-bind:is="placeholderComponent"
                  :element="element"
                ></component
                ><!-- Platzhalter Component bis der neue Slice erstellt wurde -->
              </div>
            </draggable>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <div class="col-5">
      <v-card class="mb-2">
        <v-toolbar color="dark" dark>
          <v-icon left>mdi-power-plug</v-icon>
          <v-toolbar-title>Komponenten</v-toolbar-title>
        </v-toolbar>

        <v-list>
          <draggable
            v-if="!someSliceInEdit"
            class="dragArea list-group"
            :list="contentElements"
            :group="{ name: 'elements', pull: 'clone', put: true }"
            :sort="false"
            handle=".draghandle"
          >
            <v-list-item
              v-for="(element, index) in contentElements"
              :key="index"
            >
              <v-list-item-title
                ><v-icon left class="draghandle">mdi-menu</v-icon>
                {{ element.title }}</v-list-item-title
              >
            </v-list-item>
          </draggable>

          <div v-else style="opacity: 0.2; cursor: not-allowed">
            <v-list-item
              v-for="(element, index) in contentElements"
              :key="index"
            >
              <v-list-item-title
                ><v-icon left class="draghandle">mdi-menu</v-icon>
                {{ element.name }}</v-list-item-title
              >
            </v-list-item>
          </div>
        </v-list>
      </v-card>

      <Pagemeta
        v-if="data"
        class="mb-2"
        :pageData="pageData"
        :pageId="pageId"
        @metaUpdate="metaUpdate"
        :data="this.data"
      ></Pagemeta>

      <Pagetemplate
        v-if="data"
        class="mb-2"
        :templates_id="this.data.templates_id"
        :pageId="pageId"
        :pageData="data"
      ></Pagetemplate>
    </div>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import draggable from "vuedraggable";
import Pagemeta from "@/components/pages/Pagemeta.vue";
import Pagetemplate from "@/components/pages/Pagetemplate.vue";
import PlaceholderComponent from "@/components/pages/contentelements/PlaceholderComponent.vue";
// Components
import Headline from "@/components/pages/contentelements/Headline.vue";
import Textblock from "@/components/pages/contentelements/Textblock.vue";
import Testimonials from "@/components/pages/contentelements/Testimonials.vue";
import Oba from "@/components/pages/contentelements/OBA.vue";
import Contract from "@/components/pages/contentelements/Contract.vue";
import Contracts from "@/components/pages/contentelements/Contracts.vue";
import Shop from "@/components/pages/contentelements/Shop.vue";
import Form from "@/components/pages/contentelements/Form.vue";
import Columns from "@/components/pages/contentelements/Columns.vue";
import Button from "@/components/pages/contentelements/Button.vue";
import ParallaxHero from "@/components/pages/contentelements/ParallaxHero.vue";
import Prompt from "@/components/pages/contentelements/Prompt.vue";
import Memory from "@/components/pages/contentelements/Memory.vue";
import DebuggingGame from "@/components/pages/contentelements/DebuggingGame.vue";
import PicturePuzzle from "@/components/pages/contentelements/PicturePuzzle.vue";
import SimpleImage from "@/components/pages/contentelements/SimpleImage.vue";

export default {
  name: "clone",
  mixins: [apiRequest],
  display: "Clone,Transitions",
  order: 2,

  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  created: function () {
    if (this.id) {
      this.pageId = this.id;
    } else {
      this.pageId = this.$route.params.id;
    }

    this.getData(); // call it immediatly
  },

  components: {
    Pagemeta,
    Pagetemplate,
    draggable,
    PlaceholderComponent,
    Headline,
    Textblock,
    Testimonials,
    Oba,
    Contract,
    Contracts,
    Shop,
    Form,
    Columns,
    Button,
    ParallaxHero,
    Prompt,
    Memory,
    DebuggingGame,
    PicturePuzzle,
    SimpleImage,
  },
  /*  
  Später: Die einzelnen (Components/Slices) Vorlagen sollten auch direkt aus der DB gelesen werden.
  */

  data() {
    return {
      pageId: null,
      pageData: {},
      someSliceInEdit: false,
      pageVersion: "dev",
      devbuttonColor: "primary",
      livebuttonColor: "primary",
      devbuttonDisabled: true,
      livebuttonDisabled: false,
      slicesDev: this.data.branches.dev.slices,
      slicesLive: this.data.branches.live.slices,
      contentElements: null,
      placeholderComponent: "PlaceholderComponent",
      pageComponents: [], // get via api
      componentKey: 0,
    };
  },

  methods: {
    whatUrl() {
      if (!this.data.companies_id) {
        return (
          this.$frontendUrl +
          "?uuid=" +
          this.data.uuid +
          "&type=page&branch=" +
          this.pageVersion +
          "&t=" +
          this.$store.getters["auth/token"]
        );
      } else {
        return (
          this.$frontendUrl +
          "?uuid=" +
          this.data.uuid +
          "&type=page&branch=" +
          this.pageVersion +
          "&companyset=" +
          this.data.companies_id
        );
      }
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest("components"); // await data from mixin
      this.preloader = false;

      if (response.status === 200) {
        this.contentElements = response.data.data;
      }
    },

    changeElement: async function (evt) {
      if (evt.moved) {
        /* Element wurde verschoben */

        let newIndex = evt.moved.newIndex;
        let oldIndex = evt.moved.oldIndex;
        let sliceId = evt.moved.element.data.id;

        if (newIndex !== oldIndex) {
          console.log("UPDATE SLICE-ID " + sliceId + " TO INDEX " + newIndex);

          const params = new URLSearchParams();
          params.append("prio", newIndex);

          // patch new prio
          this.preloader = true;
          let response = await this.patchRequest(
            `pages/${this.pageId}/branches/${this.pageVersion}/slices/${sliceId}`,
            params
          );
          this.preloader = false;

          if (response.status === 200) {
            /* start snackbar */
            this.$root.snackbarGlobal.snackbar = true;
            this.$root.snackbarGlobal.snackbarText = "Gespeichert!";
            this.$root.snackbarGlobal.snackbarColor = "primary";
            this.$root.snackbarGlobal.snackbarTimeout = 5000;
          }
        }
      }
    },

    addElement: async function (evt) {
      this.someSliceInEdit = true; /* set this to avoid other actions while waiting of response */

      let newIndex = evt.newIndex; // the index of the added array

      /* Hole den Componentennamen aus dem neu hinzugefügten Array (newIndex) */
      let itemName = this.slicesDev[newIndex].name;
      if (this.pageVersion === "live") {
        itemName = this.slicesLive[newIndex].name;
      }

      /* console.log("addElement +++++");
      console.log("newIndex: " + newIndex);
      console.log("itemName: " + itemName);
      console.log(`MAKE AN API REQUEST TO CREATE A NEW SLICE WITH NEWINDEX (${newIndex}) & NAME (${itemName}) & PAGEID (${this.$route.params.id})`);
      console.log(`AFTER THAT REPLACE THE PLACEHOLDER WITH THE REAL DATA.`);
      console.log("+++++"); */

      let formData = new FormData();
      formData.append("componentName", itemName);
      formData.append("prio", newIndex);

      this.preloader = true;
      let response = await this.postRequest(
        `pages/${this.pageId}/branches/${this.pageVersion}/slices`,
        "",
        formData
      ); // await data from mixin -> Formdata: title, prio,
      this.preloader = false;

      /* console.log(response);
      this.$emit('reload',true) // emit to parent-component to start reload */

      /* MUTATE ARRAY WITH RESPONSE */
      if (this.pageVersion === "dev") {
        this.slicesDev.splice(
          response.data.data[0].prio,
          1,
          response.data.data[0]
        ); // "mutate" array
      } else if (this.pageVersion === "live") {
        this.slicesLive.splice(
          response.data.data[0].prio,
          1,
          response.data.data[0]
        ); // "mutate" array
      }
    },

    async metaUpdate(value) {
      let isLive = value.isLive;

      if (!value.isLive) {
        isLive = false;
      }

      if (isLive === "1") {
        isLive = true;
      }

      const params = new URLSearchParams();
      params.append("title", value.title);
      params.append("seoDescription", value.description);
      params.append("isLive", isLive);

      this.preloader = true;
      let response = await this.putRequest(`pages/${this.pageId}`, params); // await data from mixin -> Formdata: title, prio,
      this.preloader = false;

      if (response.status === 200) {
        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Gespeichert!";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    changeVersion() {
      if (this.pageVersion === "dev") {
        this.pageVersion = "live";
        this.devbuttonDisabled = false;
        this.livebuttonDisabled = true;
        this.componentKey += 1;
      } else {
        this.pageVersion = "dev";
        this.devbuttonDisabled = true;
        this.livebuttonDisabled = false;
        this.componentKey += 1;
      }
    },

    async sliceAction(value) {
      /* Via $emit wird emitAction von der Child-Component (die jeweilige Komponente) hier hin zurückgegeben.
      Durch this.pageComponents.splice(apiResponse.index, 1, apiResponse) wird jetzt quasi das alte Objekt durch das neue ersetzt (mutated) 

      value =  = { 
        action: 'update',
        sliceId: this.sliceId,
        prio: this.prio,
        fields: {
          title: this.title,
          titleSize: this.titleSize,
        }
      }

      --
      splice: https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
      info: https://www.xspdf.com/resolution/51822272.html https://www.xspdf.com/resolution/50783808.html
      vielleicht besser: https://stackoverflow.com/a/52996768
      */

      //console.log(value);

      if (value.action === "update") {
        /* UPDATE/PATCH */

        let fields = value.fields;

        const params = new URLSearchParams();
        for (let [key, value] of Object.entries(fields)) {
          params.append("" + key + "", value);
        }

        this.preloader = true;
        let response = await this.patchRequest(
          `pages/${this.pageId}/branches/${this.pageVersion}/slices/${value.sliceId}`,
          params
        );
        this.preloader = false;

        if (response.status === 200) {
          /* MUTATE ARRAY WITH RESPONSE - Die PRIO steht im Response ja drin */
          const prio = response.data.data[0].prio;
          const data = response.data.data[0];

          if (this.pageVersion === "dev") {
            this.slicesDev.splice(prio, 1, data);
          } else if (this.pageVersion === "live") {
            this.slicesLive.splice(prio, 1, data);
          }

          /* start snackbar */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t(
            "messages.SafeSuccess.title"
          );
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else if (value.action === "delete") {
        const userChoice = await this.$dialog.confirm({
          text: "Soll dieser Slice wirklich gelöscht werden?",
          title: "Achtung!",
          waitForResult: true,
        });

        if (userChoice) {
          // okay
          this.preloader = true;
          let response = await this.deleteRequest(
            `pages/${this.pageId}/branches/${this.pageVersion}/slices/${value.sliceId}`
          ); // await data from mixin -> Formdata: title, prio,
          this.preloader = false;

          if (response.status === 200) {
            /* MUTATE ARRAY WITH RESPONSE 
            Die PRIO steht NICHT Response ja drin */

            if (this.pageVersion === "dev") {
              this.slicesDev.splice(value.prio, 1); // "mutate" array
            } else if (this.pageVersion === "live") {
              this.slicesLive.splice(value.prio, 1); // "mutate" array
            }

            /* start snackbar */
            this.$root.snackbarGlobal.snackbar = true;
            this.$root.snackbarGlobal.snackbarText = "Element wurde gelöscht!";
            this.$root.snackbarGlobal.snackbarColor = "primary";
            this.$root.snackbarGlobal.snackbarTimeout = 5000;
          }
        } else {
          // abort
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },

    aSliceIsInEdit(value) {
      /* Via $emit wird von einer Child-Component zurückgegeben, ob diese aktuell im Editiermodus ist oder nicht (true/false).
      Wenn true, werden alle Drag und Editier-Funktionen bei allen Child-Components gesperrt.
      So stelle ich sicher, dass z.B. nicht verschoben werden kann, wenn eine Component sich noch im Editiermodus befindet.
      */
      if (value) {
        this.someSliceInEdit = true;
      } else {
        this.someSliceInEdit = false;
      }
    },

    async releaseDevVersion() {
      const userChoice = await this.$dialog.confirm({
        text: `Möchtest Du diese Version veröffentlichen? Die bisherige Veröffentlichung wird damit überschrieben. Der Vorgang kann nicht rückgängig gemacht werden.`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // do it
        /* Route POST /pages/{id} mit formData: source="{sourceName}" und dest="{destName}", z.B. source="dev" und dest="live".
        Methode in Pages->copyBranch(int $pageId, string $branchSrcName, string $branchDestName) */

        const formData = new FormData();
        formData.append("source", "dev");
        formData.append("dest", "live");

        this.preloader = true;
        let response = await this.postRequest(
          `pages/${this.pageId}`,
          null,
          formData
        );
        this.preloader = false;

        if (response.status === 200) {
          /* start snackbar */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Seite wurde erfolgreich freigegeben!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          this.getData();
          this.$emit("copiedbranches"); // hiermit kann ich ein Event an die Parent-Component schicken
        } else {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Ein Fehler ist aufgetreten!";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "error";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    async draftLiveVersion() {
      const userChoice = await this.$dialog.confirm({
        text: `Möchtest Du diese Version in die Arbeitsversion kopieren? Die bisherige Arbeitsversion wird damit überschrieben. Der Vorgang kann nicht rückgängig gemacht werden.`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // do it
        /* Route POST /pages/{id} mit formData: source="{sourceName}" und dest="{destName}", z.B. source="dev" und dest="live".
        Methode in Pages->copyBranch(int $pageId, string $branchSrcName, string $branchDestName) */

        const formData = new FormData();
        formData.append("source", "live");
        formData.append("dest", "dev");

        this.preloader = true;
        let response = await this.postRequest(
          `pages/${this.pageId}`,
          null,
          formData
        );
        this.preloader = false;

        if (response.status === 200) {
          /* start snackbar */
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Seite wurde erfolgreich in die Arbeitsversion übertragen!";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          this.getData();
          this.$emit("copiedbranches"); // hiermit kann ich ein Event an die Parent-Component schicken
        } else {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Ein Fehler ist aufgetreten!";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "error";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>

<style scoped>
.draghandle {
  cursor: grab;
}
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb !important;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
